import React, { Component } from "react";

class FeelingProud extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <img
        alt="Company Name"
        src={require("../../assests/images/waves.jpeg")}
      ></img>
    );
  }
}

export default FeelingProud;
