/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Futurik Cloud Solutions",
  description:
    "We provide custom app building solutions to Salesforce Marketing Cloud community. We are experts in building Custom Journey Builder Activity, Custom Content Block and setting up Marketing Cloud Accounts.",
  og: {
    title: "Futurik Cloud Solutions",
    type: "website",
    url: "http://futurikcloud.com/",
  },
};

//Home Page
const greeting = {
  title: "Who are we?",
  logo_name: "Futurik Cloud Solutions",
  nickname: "We are SFMC App Developers.",
  subTitle:
    "We provide custom app building solutions to Salesforce Marketing Cloud community & customers. We are experts in building Custom Journey Builder Activity, Custom Content Block and setting up Marketing Cloud Accounts.",
  resumeLink: "",
  portfolio_repository: "",
  githubProfile: "",
};

const socialMediaLinks = [];

const skills = {};

// Education Page
const competitiveSites = {};

const degrees = {};

const certifications = {};

// Experience Page
const experience = {};

// Projects Page
const projectsHeader = {};

const publicationsHeader = {};

const publications = {};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {},
  addressSection: {
    title: "Address",
    subtitle: "Burnaby, British Columbia, Canada",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+1 226 977 0771",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
